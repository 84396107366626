
import { defineComponent, onMounted, ref, watch } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { campHandleEveryoneIsTrue, campHandleEmailVerification, campHandleBrPhoneNumber, campHandleCNPJ } from '@/composables/DataValidation';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore} from "@/store/LoaderStore"
import { useAuthStore } from '@/store/AuthStore';

export interface IStoreData {
  id?: number,
  cnpj: string,
  corporate_name: string,
  fantasy_name: string,
  responsible: string,
  email: string,
  telephone: string,
  id_store?: number | string,
  id_profile?: number | string | null,
  id_group?: number | string | null,
  sale_status_default: number | string,
  percent_value?: number,
  uf: string;
  city: string;
  code_regional_internal_salesman?: string;
  deleted_at?: string | null;
  reward_the_moderator?: number | boolean;
}

interface ICompanyResponse {
  data: {
    error: boolean,
    message: string,
    data: IStoreData
    errorCode: string
  },
  status: number
}

export interface IProfile {
  id: number | string,
  name: string
}

export interface IProfileResponse {
  data: {
    error: boolean,
    message: string,
    data: IProfile[]
    errorCode: string
  },
  status: number
}

function checkEmptyFields(obj: IStoreData): boolean {
  for (let key in obj) {
    if (obj[key] === "") {
      return true;
    }
  }
  return false;
}

export type TInitApprovalOptions = [
  {
    id: 1,
    name: "Moderador"
  },
  {
    id: 2,
    name: "Aprovar direto"
  }
]

export const initApprovalOptions: TInitApprovalOptions = [
  {
    id: 1,
    name: "Moderador"
  },
  {
    id: 2,
    name: "Aprovar direto"
  }
]

type TOption = { key: string, label: string }

export const UF_LIST: TOption[] = [
  { key: "AC", label: "Acre" },
  { key: "AL", label: "Alagoas" },
  { key: "AP", label: "Amapá" },
  { key: "AM", label: "Amazonas" },
  { key: "BA", label: "Bahia" },
  { key: "CE", label: "Ceará" },
  { key: "DF", label: "Distrito Federal" },
  { key: "ES", label: "Espírito Santo" },
  { key: "GO", label: "Goiás" },
  { key: "MA", label: "Maranhão" },
  { key: "MT", label: "Mato Grosso" },
  { key: "MS", label: "Mato Grosso do Sul" },
  { key: "MG", label: "Minas Gerais" },
  { key: "PA", label: "Pará" },
  { key: "PB", label: "Paraíba" },
  { key: "PR", label: "Paraná" },
  { key: "PE", label: "Pernambuco" },
  { key: "PI", label: "Piauí" },
  { key: "RJ", label: "Rio de Janeiro" },
  { key: "RN", label: "Rio Grande do Norte" },
  { key: "RS", label: "Rio Grande do Sul" },
  { key: "RO", label: "Rondônia" },
  { key: "RR", label: "Roraima" },
  { key: "SC", label: "Santa Catarina" },
  { key: "SP", label: "São Paulo" },
  { key: "SE", label: "Sergipe" },
  { key: "TO", label: "Tocantins" },
]

export default defineComponent({
  name: "StorePost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  },
  setup() {
    const initData: IStoreData = {
      cnpj: "",
      corporate_name: "",
      fantasy_name: "",
      responsible: "",
      email: "",
      telephone: "",
      id_profile: "",
      id_group: "",
      sale_status_default: 1,
      uf: "",
      code_regional_internal_salesman: "",
      city: "",
      deleted_at: "",
      reward_the_moderator: false
    }
    const postObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | IStoreData
    }>({
      isLoading: false,
      noError: true,
      data: { ...initData }
    })
    const authStore = useAuthStore()
    const loaderStore = useLoaderStore()
    const { showTimeAlert } = useAlert()
    const profileField = ref<IProfile[] | null>(null)
    const groupField = ref<any[] | null>(null)
    const approvalOptions = ref<TInitApprovalOptions>([...initApprovalOptions])

    /** Warning of unfilled fields */
    const isRequiredField = ref(false)
    const isInvalidFieldWarning = ref(false)
    const isInvalidField = ref<{ 
      cnpj: boolean,
      mail: boolean,
      phoneNumber: boolean
     }>({
      cnpj: false,
      mail: false,
      phoneNumber: false
    })

    /** Validate CNPJ */
    watch(() => postObject.value.data?.cnpj, () => {
      if(postObject.value.data?.cnpj) {
        isInvalidField.value.cnpj = !campHandleCNPJ(postObject.value.data.cnpj)
      }
    })

    /** Validate email */
    watch(() => postObject.value.data?.email, () => {
      if(postObject.value.data?.email) {
        isInvalidField.value.mail = !campHandleEmailVerification(postObject.value.data.email)
      }
    })

    /** Validate phone number */
    watch(() => postObject.value.data?.telephone, () => {
      if(postObject.value.data?.telephone) {
        isInvalidField.value.phoneNumber = !campHandleBrPhoneNumber(postObject.value.data.telephone)
      }
    })

    /** Reset reward the moderator*/
    watch(() => postObject.value.data?.sale_status_default, () => {
      if(!postObject.value.data)
        return
      if(postObject.value.data.sale_status_default == 2)
        postObject.value.data.reward_the_moderator = false
    })

    const clearWarning = () => (isRequiredField.value = false, isInvalidFieldWarning.value = false)

    async function getProfiles() {
      loaderStore.open()
      try {
        const [result1, result2] = await Promise.all([
          axios.get(`/api/getProfile`),
          axios.get(`/api/getGroup`),
        ])
        profileField.value = [{ id: "", name: "" }, ...result1.data.data]
        groupField.value = [{ id: "", name: "" }, ...result2.data.data]
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
      }
    }

    async function onSubmitForm() {

      isInvalidFieldWarning.value = false
      isRequiredField.value = false
      const { data } = postObject.value
      if(!data) {
        return
      }
      const { id_profile, id_group, code_regional_internal_salesman, deleted_at, reward_the_moderator, ...requiredFields } = data
      if(checkEmptyFields(requiredFields)) {
        return isRequiredField.value = true
      }
      if(campHandleEveryoneIsTrue(isInvalidField.value)) {
        isInvalidFieldWarning.value = true
        return isRequiredField.value = true
      }
      loaderStore.open()

      try {
        const { data } = postObject.value
        if(data) {
          const {telephone, cnpj, id_store, ...res} = data
          const postData = {
            ...res,
            reward_the_moderator,
            telephone: campHandleBrPhoneNumber(telephone, "unformatted"),
            cnpj: campHandleCNPJ(cnpj, "unformatted"),
            id_profile: id_profile ? id_profile : null,
            id_group: id_group ? id_group : null
          }
          await axios.post(`/api/postStore`, postData)
          showTimeAlert("Registro criado com sucesso!")
          postObject.value.data = { ...initData }
          return router.push("/lojas")
        }
      } catch (error) {
        if(error instanceof AxiosError) {
          showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }

        loaderStore.close()
      }
    }

    // Action / Lifecycles
    onMounted(() => getProfiles())

    return {
      postObject,
      isRequiredField,
      isInvalidFieldWarning,
      isInvalidField,
      onSubmitForm,
      profileField,
      groupField,
      approvalOptions,
      clearWarning,
      UF_LIST,
    }
  }
})
